.contact {
    padding: 2rem 0;
    padding-left: 1rem;
    padding-Right: 1rem;    
    text-align: center;
    
    background-color: transparent;
}

.contact h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: whitesmoke;
}

.contact p {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    text-align: center;
    color: lightsteelblue;
}

.contact .email-link {
    font-size: 1.2rem;
    text-align: center;
    color: whitesmoke; 
    text-decoration: none;
}

.contact .email-link:hover,
.contact .email-link:focus,
.contact .email-link:active {
    color: lightsteelblue;
}

.contact p.email {
    font-size: 1.2rem;
    margin-bottom: 4rem;
    text-align: center;
    color: whitesmoke; 
}

