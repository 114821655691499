.navbar {
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: sticky;
    top: 0;
    background: #bdbdbd;
    background: linear-gradient(0deg, #bdbdbd 50%, #a8a8a8 100%);
    background-size: 100% 4px;
    color: white;
    z-index: 1;
}

.navbar a {
    color: #555;
    text-decoration: none;
    font-family: 'Courier New', Courier, monospace; 
    font-weight: bold;
    font-size: 1.3em;
    text-shadow: 1px 1px 0 #fff, -1px -1px 0 #000;
}

@media (max-width: 600px) {
    .navbar {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .navbar a {
        font-size: 0.9em; /* Adjust this value according to your needs */
    }
}

