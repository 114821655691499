.about {
    padding: 2rem 0;
    padding-left: 1rem;
    padding-Right: 1rem;    
    text-align: center;
    
    background-color: transparent;
}

.about h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: whitesmoke;
}

.about p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: left;
    color: lightsteelblue;
}

