.services {
  padding: 0rem 0;
  padding-left: 1rem;
  padding-Right: 1rem;
  text-align: center;
  background-color: transparent;
}

.services::before {
  content: "";
  display: block;
  height: 100px; /* This should be the same height as your navbar */
  margin-top: -50px; /* This should be the negative value of the height */
  visibility: hidden;
  pointer-events: none;
}

.services h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  color: whitesmoke;
  font-weight: bold;
}

.services h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem; 
  margin-top: 2rem;
  text-align: left;   
  color: aliceblue;
  font-weight: bold;
}

.services p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  text-align: left;
  color: lightsteelblue;
}

.services-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* This will allow the columns to wrap onto the next line on smaller screens */
}

.services-column {
  width: 50%; /* Adjust this value as needed */
  padding: 1rem; /* Add some padding to create space between the columns */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.services-intro h2,
.services-intro p {
  text-align: center;
  color: white;
}

/* This media query will stack the columns on top of each other on screens smaller than 600px */
@media (max-width: 600px) {
  .services-column {
    width: 100%; /* Make the columns take up the full width on smaller screens */
  }
}



