.hero {
    padding-top: 20vh;     
    min-height: 5vh;  
    padding-top: 3rem;
    text-align: center;
    color: white;
    background-color: transparent;
}

@media (max-width: 600px) {
    .hero {
        padding-top: 3rem;
    }
}

