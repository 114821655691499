html, body, #root, .App {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
 
  flex-direction: column;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  flex-grow: 1;
}

@media (max-width: 600px) {
  .container {
      width: 100%;
  }
}


