footer {
    height: 40px;
    padding: 1rem;
    text-align: center;
    background: #bdbdbd;
    background: linear-gradient(0deg, #bdbdbd 50%, #a8a8a8 100%);
    background-size: 100% 4px;
    color: white;
    z-index: 1;
    position: fixed; 
    bottom: 0; 
    width: 100%; 
}

footer p {
    color: #555;
    text-decoration: none;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 1.3em;
    text-shadow: 1px 1px 0px #fff, -1px -1px 0px #000;
}

@media (max-width: 600px) {
    footer {
        position: fixed;
    }

    footer p {
        font-size: 0.9em; /* Adjust this value according to your needs */
    }
}

